<template>
    <div>
        <!-- content -->
        <!-- title -->
        <div class="container-fluid bg-slider-expert-register px-0 position-relative">
            <div class="row position-relative m-0">
                <div class="col-12 py-5 px-0">
                    <div class="container py-5">
                        <div class="row">
                            <div class="col-12 col-lg-6 offset-lg-6">
                                <h1 class="d-lg-block d-none text-white mp-rbk mp-font-weight-medium font-size-expert-register-title">Selbst Experte werden</h1>
                                <h1 class="d-lg-none d-block text-white mp-rbk mp-font-weight-medium font-size-expert-register-title-mobile">Selbst Experte werden</h1>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-6 offset-lg-6">
                                <p class="d-lg-block d-none text-white mp-mts font-size-expert-register-subtitle py-4">Bewirb dich jetzt und erstelle ein Profil, um dich vorzustellen, deine Angebote zu inserieren und Kunden oder Klienten zu generieren.</p>
                                <p class="d-lg-none d-block text-white mp-mts font-size-expert-register-subtitle-mobile py-4">Bewirb dich jetzt und erstelle ein Profil, um dich vorzustellen, deine Angebote zu inserieren und Kunden oder Klienten zu generieren.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-6 col-md-6 offset-lg-6">
                                <router-link :to="`/expert/register`">
                                    <button type="button" class="btn btn-mp-green font-size-btn-expert-register mp-font-weight-medium py-2 px-5 w-100">Als Experte bewerben</button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /title -->
        <!-- advantages -->
        <div class="container-fluid bg-lightblue py-4 py-lg-5">
            <div class="row">
                <div class="col-12 pb-4">
                    <span class="mp-rbk mp-font-weight-semi-bold font-size-expert-register-advantages-title d-block text-center">Deine Vorteile</span>
                </div>
            </div>

            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-4 mb-4">
                        <div class="card py-2 px-1 form-rounded-left form-rounded-right form-shadow h-100">
                            <div class="card-body">
                                <!--<h5 class="card-title mp-rbk font-size-expert-register-advantages-card-title text-center mb-4">1.</h5>-->
                                <p class="card-text mp-qks mp-font-weight-medium font-size-expert-register-advantages-card-text text-center">In deinem individuell gestaltbaren Expertenprofil kannst du dich und deine Angebote professionell bewerben.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 mb-4">
                        <div class="card py-2 px-1 form-rounded-left form-rounded-right form-shadow h-100">
                            <div class="card-body">
                                <!--<h5 class="card-title mp-rbk font-size-expert-register-advantages-card-title text-center mb-4">2.</h5>-->
                                <p class="card-text mp-qks mp-font-weight-medium font-size-expert-register-advantages-card-text text-center">Durch zahlreiche Kontaktmöglichkeiten und Erfahrungsberichte gewinnst du einfach und rasch neue Kunden.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 mb-4">
                        <div class="card py-2 px-1 form-rounded-left form-rounded-right form-shadow h-100">
                            <div class="card-body">
                                <!--<h5 class="card-title mp-rbk font-size-expert-register-advantages-card-title text-center mb-4">3.</h5>-->
                                <p class="card-text mp-qks mp-font-weight-medium font-size-expert-register-advantages-card-text text-center">Mit unseren kostengünstigen und monatlich kündbaren Paketen erhältst du die perfekte Lösung.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- /advantages -->
        <!-- how it works -->
        <div class="container-fluid py-3 py-lg-5">
            <div class="row">
                <div class="col-12">
                    <div class="container py-4">
                        <div class="row mb-lg-5 mb-4">
                            <div class="col-12">
                                <span class="d-lg-block d-none mp-rbk mp-font-weight-semi-bold font-size-expert-register-how-it-works-title d-block text-center">So funktioniert’s</span>
                                <span class="d-lg-none d-block mp-rbk mp-font-weight-semi-bold font-size-expert-register-how-it-works-title-mobile d-block text-center">So funktioniert’s</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <!-- tabs -->
                                <div class="row d-flex align-items-start">

                                    <!-- desktop -->
                                    <div class="d-lg-block d-none nav flex-column nav-tabs col-12 col-lg-3 mb-5" id="hiw" role="tablist" aria-orientation="vertical">
                                        <button class="nav-link active px-0 expert-register-how-it-works-tab-title mp-rbk font-size-expert-register-how-it-works-tab-title text-lg-start"
                                                id="tab1"
                                                data-bs-toggle="tab"
                                                data-bs-target="#tab1-content"
                                                type="button" role="tab"
                                                aria-controls="tab1-content"
                                                aria-selected="true">
                                            erstelle dein Experten-Konto
                                        </button>
                                        <button class="nav-link px-0 expert-register-how-it-works-tab-title mp-rbk font-size-expert-register-how-it-works-tab-title text-lg-start"
                                                id="tab2"
                                                data-bs-toggle="tab"
                                                data-bs-target="#tab2-content"
                                                type="button" role="tab"
                                                aria-controls="tab2-content"
                                                aria-selected="false">
                                            gestalte dein Experten-Profil
                                        </button>
                                        <button class="nav-link px-0 expert-register-how-it-works-tab-title mp-rbk font-size-expert-register-how-it-works-tab-title text-lg-start"
                                                id="tab3"
                                                data-bs-toggle="tab"
                                                data-bs-target="#tab3-content"
                                                type="button" role="tab"
                                                aria-controls="tab3-content"
                                                aria-selected="false">
                                            wähle dein individuelles Paket
                                        </button>
                                    </div>
                                    <div class="d-lg-block d-none tab-content col-12 col-lg-9" id="hiw-content">
                                        <div class="tab-pane fade show active" id="tab1-content" role="tabpanel" aria-labelledby="tab1">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-12 col-lg-7">
                                                        <p class="mp-qks font-size-how-it-works-description mp-font-weight-medium">
                                                            Registriere dich in wenigen Schritten, durch Eingabe deiner persönlicher Daten und bestätige deine E-Mailadresse.
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-lg-5">
                                                        <img class="w-100 form-rounded-left form-rounded-right" src="../../assets/img/img-funktionen.png" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="tab2-content" role="tabpanel" aria-labelledby="tab2">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-12 col-lg-7">
                                                        <p class="mp-qks font-size-how-it-works-description mp-font-weight-medium">
                                                            Gestalte sofort dein Experten-Profil, indem du deine Fachgebiete, Kontaktdaten, Fotos und eventuell Videos hinzufügst. Lade zusätzlich einen Qualifikationsnachweis für deine Fachgebiete hoch und werde innerhalb der nächsten 48 Stunden verifiziert.
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-lg-5">
                                                        <img class="w-100 form-rounded-left form-rounded-right" src="../../assets/img/img-funktionen.png" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-12 col-lg-7">
                                                        <p class="mp-qks font-size-how-it-works-description mp-font-weight-medium">
                                                            Wähle aus drei verschiedenen Paketen aus um dein Experten-Profil zu veröffentlichen. Ab jetzt bist du für alle Nutzer von mergeplate.com sichtbar.
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-lg-5">
                                                        <img class="w-100 form-rounded-left form-rounded-right" src="../../assets/img/img-funktionen.png" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- mobile -->
                                    <div class="d-lg-none d-block nav flex-column nav-tabs col-12 mb-5" id="hiw-mobile" role="tablist" aria-orientation="vertical">
                                        <button class="nav-link active px-0 expert-register-how-it-works-tab-title mp-rbk font-size-expert-register-how-it-works-tab-title-mobile w-100"
                                                id="tab1-mobile"
                                                data-bs-toggle="tab"
                                                data-bs-target="#tab1-content-mobile"
                                                type="button" role="tab"
                                                aria-controls="tab1-content-mobile"
                                                aria-selected="true">
                                            erstelle dein Experten-Konto
                                        </button>
                                        <button class="nav-link px-0 expert-register-how-it-works-tab-title mp-rbk font-size-expert-register-how-it-works-tab-title-mobile w-100"
                                                id="tab2-mobile"
                                                data-bs-toggle="tab"
                                                data-bs-target="#tab2-content-mobile"
                                                type="button" role="tab"
                                                aria-controls="tab2-content-mobile"
                                                aria-selected="false">
                                            gestalte dein Experten-Profil
                                        </button>
                                        <button class="nav-link px-0 expert-register-how-it-works-tab-title mp-rbk font-size-expert-register-how-it-works-tab-title-mobile w-100"
                                                id="tab3-mobile"
                                                data-bs-toggle="tab"
                                                data-bs-target="#tab3-content-mobile"
                                                type="button" role="tab"
                                                aria-controls="tab3-content-mobile"
                                                aria-selected="false">
                                            wähle dein individuelles Paket
                                        </button>
                                    </div>
                                    <div class="d-lg-none d-block tab-content col-12 col-lg-9" id="hiw-content-mobile">
                                        <div class="tab-pane fade show active" id="tab1-content-mobile" role="tabpanel" aria-labelledby="tab1-mobile">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-12 col-lg-7">
                                                        <p class="mp-qks font-size-how-it-works-description-mobile mp-font-weight-medium">
                                                            Registriere dich in wenigen Schritten, durch Eingabe deiner persönlicher Daten und bestätige deine E-Mailadresse.
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-lg-5">
                                                        <img class="w-100 form-rounded-left form-rounded-right" src="../../assets/img/img-funktionen.png" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="tab2-content-mobile" role="tabpanel" aria-labelledby="tab2-mobile">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-12 col-lg-7">
                                                        <p class="mp-qks font-size-how-it-works-description-mobile mp-font-weight-medium">
                                                            Gestalte sofort dein Experten-Profil, indem du deine Fachgebiete, Kontaktdaten, Fotos und eventuell Videos hinzufügst. Lade zusätzlich einen Qualifikationsnachweis für deine Fachgebiete hoch und werde innerhalb der nächsten 48 Stunden verifiziert.
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-lg-5">
                                                        <img class="w-100 form-rounded-left form-rounded-right" src="../../assets/img/img-funktionen.png" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="tab3-content-mobile" role="tabpanel" aria-labelledby="tab3-mobile">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-12 col-lg-7">
                                                        <p class="mp-qks font-size-how-it-works-description-mobile mp-font-weight-medium">
                                                            Wähle aus drei verschiedenen Paketen aus um dein Experten-Profil zu veröffentlichen. Ab jetzt bist du für alle Nutzer von mergeplate.com sichtbar.
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-lg-5">
                                                        <img class="w-100 form-rounded-left form-rounded-right" src="../../assets/img/img-funktionen.png" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- /tabs -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /how it works -->

        <!-- payment option -->
        <div class="container-fluid bg-lightblue py-3 py-lg-5">
            <div class="row">
                <div class="col-12">
                    <div class="container py-4">
                        <div class="row">
                            <div class="col-12">
                                <span class="d-lg-block d-none mp-rbk mp-font-weight-semi-bold font-size-expert-register-payment-title text-center">Verfügbare Zahlungspläne</span>
                                <span class="d-lg-none d-block mp-rbk mp-font-weight-semi-bold font-size-expert-register-payment-title-mobile text-center">Verfügbare Zahlungspläne</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-10 offset-1 py-4">
                                <p class="mp-qks font-size-payment-subtitle mp-font-weight-medium text-center">Wähle aus drei verschiedenen Paketen, den für dich besten Zahlungsplan. </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-8 offset-md-2 col-lg-4 offset-lg-0 mb-3" v-for="p in products" :key="`${keyPrefix}_p_${p.id}`">
                                <ProductCard :product="p" :hide-button="true" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /payment option -->

        <!-- calltoaction -->
        <div class="container-fluid bg-slider-expert-register-call-to-action px-0 position-relative">
            <div class="row position-relative m-0">
                <div class="col-12 py-5 px-0">
                    <div class="container py-5">
                        <div class="row">
                            <div class="col-12 col-lg-8 py-2">
                                <span class="d-lg-block d-none text-center text-lg-start mp-rbk font-size-expert-register-call-to-action-text mp-font-weight-medium text-white">Gewinne mehr Kunden auf <b>mergeplate.com</b></span>
                                <span class="d-lg-none d-block text-center text-lg-start mp-rbk font-size-expert-register-call-to-action-text-mobile mp-font-weight-medium text-white">Gewinne mehr Kunden mit <b>mergeplate.com</b></span>
                            </div>
                            <div class="col-12 col-lg-4 py-2 d-flex justify-content-center justify-content-lg-end">
                                <router-link :to="`/expert/register`">
                                    <button type="button" class="align-self-center btn btn-mp-green font-size-btn-expert-register mp-font-weight-medium py-2 px-4">Als Experte bewerben</button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /calltoaction -->
        <!-- /content -->
    </div>
</template>




<script>
    import {mixinBasics} from "../../mixins/basics";
    import ProductCard from "../../components/ProductCard";


    export default {
        name: "ExpertRegister",

        metaInfo() {
            return {
                title: 'Als Experte bewerben',
                meta: [
                    { name: 'Als Experte bewerben | mergeplate.com' },
                    { title: 'Als Experte bewerben | mergeplate.com' }
                ].concat(this.buildOpenGraphMetaTags(
                    'Als Experte bewerben | mergeplate.com',
                    'Als Experte bewerben - mergeplate.com - finde deinen Experten auf der Plattform für mentale und physische Gesundheit',
                    this.$route.path
                ))
            };
        },

        components: {
            ProductCard
        },

        mixins: [
            mixinBasics
        ],

        data() {
            return {
                keyPrefix: 'expReg'
            };
        },

        computed: {
            products() {
                return this._products.map(p => {
                    let meta = p.stripe_product_detail.metadata;
                    return {
                        id: p.id,
                        title: p.title,
                        colorClass: meta.color_class,
                        icon: meta.icon,
                        price: p.price,
                        paymentPlan: meta.text_desc_plan,
                        paymentInfosHeader: meta.text_desc_info_header && meta.text_desc_info_header.trim() !== '' ? meta.text_desc_info_header : undefined,
                        paymentInfos: meta.text_desc_info,
                        label: meta.text_title,
                        labelPaymentPlan: meta.text_sub_title,
                        stripePriceId: p.stripe_price_id
                    };
                });
            },
        },

        methods: {
            doRegister() {
                this.$router.push(`/expert/register`);
            }
        },

        mounted() {
            window.scrollTo(0,0);
        }
    }
</script>




<style scoped>

</style>